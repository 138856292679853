import { ToastContainer } from "@alphaneun/ui-library";
import { PrismicProvider } from "@prismicio/react";
import { Link } from "gatsby";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import React from "react";

import { repositoryConfigs } from "./src/utils/prismicPreviews";

export const wrapRootElement = ({ element }) => (
    <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
            <Link to={href} {...props} />
        )}
    >
        <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
            {element}
            <ToastContainer></ToastContainer>
        </PrismicPreviewProvider>
    </PrismicProvider>
);

import {
    ContainerSize,
    Headline,
    HeadlineType,
    InPageNavigation,
    InPageNavigationSection,
    Quote,
    Section,
    Stage,
    StageSize,
    Text,
    TextType,
} from "@alphaneun/ui-library";
import { PrismicRichText } from "@prismicio/react";
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { Layout } from "../components/Layout";
import SEO from "../components/SEO";
import { Article, ContentBlock } from "../types/article";
import useSiteMetadata from "../utils/useSiteMetadata";
import "./articlepage.scss";

export interface ArticleProps {
    data: {
        prismicArticle: Article;
    };
}

export const query = graphql`
    query BlogPostQuery($id: String) {
        prismicArticle(id: { eq: $id }) {
            _previewable
            id
            uid
            lang
            type
            url
            data {
                title
                category
                author
                meta_title
                meta_keywords
                meta_description
                content {
                    navigation_title
                    text {
                        richText
                    }
                    image {
                        localFile {
                            ...constrainedImage
                        }
                    }
                }
                featured_image {
                    localFile {
                        ...fullSizeImage
                    }
                }
            }
            last_publication_date
        }
    }
`;

const ArticlePage: React.FC<ArticleProps> = ({ data }: ArticleProps) => {
    if (!data) return null;

    const { uid, last_publication_date } = data.prismicArticle,
        { url } = useSiteMetadata(),
        article = data.prismicArticle.data,
        {
            title,
            author,
            featured_image,
            category,
            content,
            meta_title,
            meta_keywords,
            meta_description,
        } = article;

    return (
        <Layout>
            <SEO
                title={meta_title}
                description={meta_description}
                url={`${url}/${uid}`}
                keywords={meta_keywords
                    ?.split(", ")
                    .concat([category.toLowerCase()])}
            />

            <Stage
                showAnimation={false}
                size={StageSize.Small}
                text=""
                image={
                    <GatsbyImage
                        //@ts-ignore
                        image={getImage(
                            featured_image.localFile.childImageSharp
                                .gatsbyImageData,
                        )}
                        alt={""}
                        loading="eager"
                    />
                }
            />

            <Section
                containerSize={ContainerSize.Small}
                contentClassName="BlogPost"
            >
                <Headline>{title}</Headline>

                <div className="BlogPost__info">
                    <Headline type={HeadlineType.H4}>{category}</Headline>

                    <Text type={TextType.BodySmall}>Author: {author}</Text>

                    <Text type={TextType.BodySmall}>
                        Veröffentlicht am{" "}
                        {dayjs(last_publication_date).format("DD.MM.YYYY")}
                    </Text>
                </div>

                {/* @ts-ignore */}
                <div className="BlogPost__body">
                    <InPageNavigation>
                        {content.map((block: ContentBlock, index: number) => {
                            if (!!block.navigation_title) {
                                return (
                                    <InPageNavigationSection
                                        name={block.navigation_title}
                                        key={index}
                                    >
                                        <Block block={block} />
                                    </InPageNavigationSection>
                                );
                            } else {
                                return <Block key={index} block={block} />;
                            }
                        })}
                    </InPageNavigation>
                </div>
            </Section>
        </Layout>
    );
};

type BlockProps = {
    block: ContentBlock;
};

export const Block = ({ block }: BlockProps) => (
    <React.Fragment>
        {block.image?.localFile && (
            <GatsbyImage
                image={getImage(
                    block.image.localFile.childImageSharp.gatsbyImageData,
                )}
                alt={""}
                loading="lazy"
            />
        )}

        {block.text && (
            <PrismicRichText
                field={block.text.richText}
                components={{
                    paragraph: ({ children }) => <Text>{children}</Text>,
                    heading1: ({ children }) => (
                        <Headline type={HeadlineType.H1}>{children}</Headline>
                    ),
                    heading2: ({ children }) => (
                        <Headline type={HeadlineType.H2}>{children}</Headline>
                    ),
                    heading3: ({ children }) => (
                        <Headline type={HeadlineType.H3}>{children}</Headline>
                    ),
                    heading4: ({ children }) => (
                        <Headline type={HeadlineType.H4}>{children}</Headline>
                    ),
                    preformatted: ({ children }) => <Quote>{children}</Quote>,
                }}
            />
        )}
    </React.Fragment>
);

export default withPrismicPreview(ArticlePage);

import "./Layout.scss";

import {
    BasePageLayout,
    ContactFormData,
    FooterType,
    HeaderNavItem,
    InPageNavigationProps,
    ToastType,
    useToastQueue,
} from "@alphaneun/ui-library";
import classNames from "classnames";
import { Link, navigate } from "gatsby";
import React, { ReactNode } from "react";

import { Footer } from "./footer/WebsiteFooter";

export interface LayoutProps {
    children?: ReactNode;
    invertedHeader?: boolean;
    inPageNavigationProps?: InPageNavigationProps;
}

const subjectOptions = [
    { label: "Generelles", value: "generelles" },
    { label: "Services", value: "services" },
    { label: "Jobs", value: "jobs" },
];

export const Layout: React.FC<LayoutProps> = ({
    children,
    invertedHeader = true,
}: LayoutProps) => {
    const { toast } = useToastQueue(),
        handleOnSubmit = async (data: ContactFormData) => {
            const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_CONTACT_FORM_PORTAL_ID}/${process.env.GATSBY_HUBSPOT_CONTACT_FORM_FORM_ID}`;

            const inputData = {
                submittedAt: Date.now(),
                skipValidation: true,
                fields: [
                    {
                        name: "email",
                        value: data.email,
                    },
                    {
                        name: "name",
                        value: data.name,
                    },
                    {
                        name: "subject",
                        value: data.subject ? data.subject : "",
                    },
                    {
                        name: "message",
                        value: data.message,
                    },
                ],
            };

            const finalData = JSON.stringify(inputData),
                response = await fetch(url, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: finalData,
                    method: "POST",
                });

            if (response.ok) {
                toast("Deine Nachricht ist erfolgreich abgeschickt worden.", {
                    type: ToastType.Success,
                    duration: 5000,
                });
                return true;
            } else {
                toast(
                    "Irgend etwas ist schief gelaufen, bitte versuch es später nochmal.",
                    {
                        type: ToastType.Error,
                        duration: 5000,
                    },
                );
                return false;
            }
        };

    return (
        <>
            <BasePageLayout
                showBackgroundAnimation={false}
                footerContent={<Footer />}
                footerProps={{
                    type: FooterType.Ribbon,
                    contactFormProps: {
                        contactSubjects: subjectOptions,
                        onSubmitForm: handleOnSubmit,
                        privacyPolicyLabel: (
                            <span>
                                Ich habe die{" "}
                                <Link to="/datenschutz-cookieinformationen">
                                    Datenschutzbestimmungen
                                </Link>{" "}
                                gelesen und stimme diesen zu.
                            </span>
                        ),
                    },
                }}
                headerProps={{
                    inverted: invertedHeader,
                    transparent: true,
                    navItems: [
                        {
                            label: "Über uns",
                            href: "/ueber-uns",
                            render: (props) =>
                                renderHeaderLink({
                                    inverted: invertedHeader,
                                    ...props,
                                }),
                        },
                        {
                            label: "Jobs",
                            href: "/jobs",
                            render: (props) =>
                                renderHeaderLink({
                                    inverted: invertedHeader,
                                    ...props,
                                }),
                        },
                        {
                            label: "Magazin",
                            href: "/magazin",
                            render: (props) =>
                                renderHeaderLink({
                                    inverted: invertedHeader,
                                    ...props,
                                }),
                        },
                    ],
                    onLogoClick: () => navigate("/"),
                }}
            >
                {children}
            </BasePageLayout>
        </>
    );
};

interface HeaderLinkProps extends HeaderNavItem {
    inverted?: boolean;
}

const renderHeaderLink = ({ label, href, inverted }: HeaderLinkProps) => {
    const css = classNames({
        Layout__headerLink: true,
        "Layout__headerLink--inverted": inverted,
    });
    return (
        <Link
            className={css}
            activeClassName="Layout__headerLink--active"
            to={href}
        >
            {label}
        </Link>
    );
};

export default Layout;

module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-e7e21f6f34/0/cache/gatsby-plugin-manifest-npm-5.4.0-a2035c9661-3b473b50bd.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"alphaneun.de","short_name":"alphaneun","start_url":"/","background_color":"#f6f2e7","theme_color":"#ff1dc0","display":"standalone","icon":"src/assets/images/favicon.png","cache_busting_mode":"none","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-ce32f997df/0/cache/gatsby-remark-images-npm-7.4.0-3467158925-a2ddf98d69.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"withWebp":true,"linkImagesToOriginal":false,"wrapperStyle":"margin-left:0","showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-prismic-previews-virtual-ef5a4075f9/0/cache/gatsby-plugin-prismic-previews-npm-5.2.10-db8eb2b194-8b37c9ef65.zip/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"alphaneun","accessToken":"MC5ZbS1pWUJFQUFDMEExNTl3.77-9OO-_ve-_vRnvv71s77-9Ke-_ve-_ve-_vX7vv73vv73vv71M77-977-9LDlIUO-_vXvvv71lBSDvv71uOg","promptForAccessToken":true,"apiEndpoint":"https://alphaneun.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-df2deb8e26/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { Headline, HeadlineType, Text, TextType } from "@alphaneun/ui-library";
import { Link } from "gatsby";
import React from "react";
import "./WebsiteFooter.scss";

export interface LinkItem {
    label: string;
    href: string;
}

export interface FooterProps {
    linkItems?: LinkItem[];
    title?: string;
    inverted?: boolean;
}

export const Footer: React.FC<FooterProps> = ({
    title = "Sektionen",
    inverted = true,
    linkItems = [
        {
            label: "Über uns",
            href: "/ueber-uns",
        },
        {
            label: "Jobs",
            href: "/jobs",
        },
        {
            label: "Magazin",
            href: "/magazin",
        },
        {
            label: "Datenschutz",
            href: "/datenschutz-cookieinformationen",
        },
        {
            label: "Impressum",
            href: "/impressum",
        },
    ],
}): JSX.Element => {
    const css = [
        "WebsiteFooter",
        inverted ? "WebsiteFooter--inverted" : "",
    ].join(" ");
    return (
        <div className={css}>
            <Headline type={HeadlineType.H2}>{title}</Headline>
            <ul className="WebsiteFooter__links">
                {linkItems.map((link, i) => {
                    return (
                        <li key={i}>
                            <Link to={link.href}>
                                <Text type={TextType.BodyBig}>
                                    <span className="WebsiteFooter__label">
                                        {link.label.toUpperCase()}
                                    </span>
                                </Text>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

import { useStaticQuery, graphql } from "gatsby";

interface SiteMetaProps {
    title?: string;
    description?: string;
    url?: string;
    author?: string;
    keywords?: string[];
    image?: string;
}

const useSiteMetadata = (): SiteMetaProps => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        url
                        keywords
                        image
                    }
                }
            }
        `,
    );
    return site.siteMetadata;
};

export default useSiteMetadata;
